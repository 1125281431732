<template>
  <label :class="classes">
    <input type="checkbox" class="rcc-checkbox__check-input" :value="inputValue" v-model="modelValue">
    <span class="rcc-checkbox__checkbox">{{ label }}</span>
  </label>
</template>

<script>
const COLORS = ['red-inactive']

export default {
  name: 'RccCheckbox',

  props: {
    inputValue: {
      type: [String, Number],
      default: ''
    },

    value: {
      type: [Array, Boolean],
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: null
    }
  },

  computed: {
    modelValue: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    },

    classes() {
      const baseClass = 'rcc-checkbox'
      return [baseClass, this.color && baseClass + '_theme_' + this.color]
    }
  }
}
</script>

<style lang="scss" scoped>
$base-class: '.rcc-checkbox';
$checkbox-translate: 'translateY(-50%)';

#{$base-class} {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: color .4s ease;
  color: $base-text-color;
  cursor: pointer;

  &__check-input {
    display: none;
  }

  &__checkbox {
    position: relative;
    display: inline-block;
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 17px;
      height: 17px;
      top: 50%;
      transform: #{$checkbox-translate};
      border: 1px solid $checkbox-border-color;
      border-radius: $checkbox-border-radius;
      transition: background .1s ease, border-color .4s ease;
      z-index: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      transform: #{$checkbox-translate};
      left: 6px;
      width: 5px;
      height: 11px;
      opacity: 0;
      transform: #{$checkbox-translate} rotate(45deg) scale(0);
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transition: all .3s ease;
      transition-delay: .15s;
      z-index: 1;
    }
  }

  &__check-input:checked ~ #{$base-class}__checkbox {
    color: $primary;

    &::before {
      border-color: transparent;
      background: $primary;
      animation: jelly .6s ease;
    }

    &::after {
      opacity: 1;
      transform: #{$checkbox-translate} rotate(45deg) scale(1);
    }
  }

  &:hover {
    color: $primary;

    #{$base-class}__checkbox {
      &::before {
        border-color: $primary;
      }
    }
  }

  &_theme {
    &_red-inactive {
      color: $error;

      #{$base-class}__checkbox {
        &::before {
          border-color: $error;
        }
      }
    }
  }
}

@keyframes jelly {
  from {
    transform: #{$checkbox-translate} scale(1, 1);
  }
  30% {
    transform: #{$checkbox-translate} scale(1.25, 0.75);
  }
  40% {
    transform: #{$checkbox-translate} scale(0.75, 1.25);
  }
  50% {
    transform: #{$checkbox-translate} scale(1.15, 0.85);
  }
  65% {
    transform: #{$checkbox-translate} scale(.95, 1.05);
  }
  75% {
    transform: #{$checkbox-translate} scale(1.05, .95);
  }
  to {
    transform: #{$checkbox-translate} scale(1, 1);
  }
}
</style>
